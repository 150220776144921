import { PDFExport } from './PDFExport.js'
import moment from 'moment'

export class CompaniesStatsExport extends PDFExport {
    constructor(user, company, ...args) {
        super(...args)
        this.user = user
        this.company = company
    }

    header() {
        // ----------
        //heading texts
        this.doc.setFontSize(10)
        this.doc.setTextColor(9, 9, 84)
        this.doc.setFont('Montserrat-Bold')
        this.doc.text(this.company.name.toUpperCase(), 15, 28)

        let companyNameLength =
            20 + this.doc.getTextWidth(this.company.name.toUpperCase())

        this.doc.line(companyNameLength, 20, companyNameLength, 30)
        companyNameLength += 5

        if (this.user) {
            this.doc.text(this.user.firstname, companyNameLength, 28)
            this.doc.text(
                this.user.lastname,
                companyNameLength +
                    this.doc.getTextWidth(this.user.firstname) +
                    2,
                28
            )
        }
    }

    footer() {
        this.doc.text(
            this.$t('stats.export.edit_at') + moment().format('DD/MM/YYYY'),
            15,
            566
        )
    }

    title() {
        this.doc.setPage(1)
        this.doc.setFont('Montserrat-Bold')
        this.doc.setFontSize(20)
        this.doc.setTextColor(9, 9, 84)
        this.doc.text(
            this.$t('stats.export.pdf_content.title').toUpperCase(),
            15,
            70
        )

        this.doc.setDrawColor(9, 9, 84)
        this.doc.setLineWidth(0.5)
        this.doc.line(15, 78, 100, 78)

        this.doc.setFont('Montserrat-Regular')
        this.doc.setFontSize(10)
    }

    period(dateType, dateRange) {
        let periodsTexts = {
            0: {
                textPreiod: this.$t('stats.export.pdf_content.week_period'),
                text: this.$t('stats.export.pdf_content.since_week'),
            },
            1: {
                textPreiod: this.$t('stats.export.pdf_content.month_period'),
                text: this.$t('stats.export.pdf_content.since_month'),
            },
            2: {
                textPreiod: this.$t('stats.export.pdf_content.whole_period'),
                text: this.$t('stats.export.pdf_content.since_whole'),
            },
        }
        if (dateType in periodsTexts) {
            let textContent = periodsTexts[dateType].textPreiod
            let textSize = this.doc.getTextWidth(textContent)
            this.doc.text(textContent, 430 - textSize, 28)
            this.doc.setFont('Montserrat-Regular')
            this.doc.setFontSize(10)
            this.doc.text(periodsTexts[dateType].text, 15, 90)
        } else {
            this.doc.text(this.$t('stats.export.pdf_content.period'), 325, 28)
            let periodeWidth =
                325 +
                this.doc.getTextWidth(
                    this.$t('stats.export.pdf_content.period')
                )
            this.doc.text(dateRange[0] + ' - ', periodeWidth, 28)
            periodeWidth += this.doc.getTextWidth(dateRange[0] + ' - ')
            this.doc.text(dateRange[1], periodeWidth, 28)

            this.doc.setFont('Montserrat-Regular')
            this.doc.setFontSize(10)
            this.doc.text(
                this.$t('stats.export.pdf_content.period_from'),
                15,
                90
            )
            periodeWidth =
                17 +
                this.doc.getTextWidth(
                    this.$t('stats.export.pdf_content.period_from')
                )
            this.doc.text(dateRange[0] + ' - ', periodeWidth, 90)
            periodeWidth += this.doc.getTextWidth(dateRange[0] + ' - ')
            this.doc.text(dateRange[1], periodeWidth, 90)
        }
    }
}
