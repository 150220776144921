import { Structure } from '@libs/Structures/Structure.js'
import { Collection } from '@libs/Collections/Collection.js'
import { FormationsProvider } from '@providers/FormationsProvider.js'
import { FORMATION_TYPE } from '@libs/Collections/Formation.js'
import { FormationSessionStructure } from '@libs/Structures/FormationSessionStructure.js'
import { reactive } from 'vue'
import i18n from '@core/i18n/index.js'

export class FormationStructure extends Structure {
    constructor(...args) {
        super(...args)
        this.formObjectives = []
        return reactive(this)
    }
    get icon() {
        switch (this.content_type) {
            case 'rab':
                return 'fa-cubes'
            case 'video360':
                return '$video360'
            case 'apk':
                return '$apk'
            case 'webvr':
                return '$webvr'
        }
        if (this.content_type == 'rab') {
            return 'fa-cubes'
        }
    }
    get modulesCollection() {
        return new Collection(this.modules)
    }

    get sectionsCollection() {
        return new Collection(this.sections)
    }

    get isVR() {
        return this.type == FORMATION_TYPE.VR
    }

    get isElearning() {
        return this.type == FORMATION_TYPE.EL
    }

    get lastSession() {
        if (!this.last_session) return null
        return new FormationSessionStructure(this.last_session)
    }

    static getRules() {
        return {
            name: 'required',
            original_name: 'required',
            slug: 'required',
            pack_id: 'required',
            description: 'required',
            nb_minutes: 'required',
            can_see_question_stats: 'required',
            objectives: 'required',
        }
    }

    static getDefault() {
        const structure = new FormationStructure()
        return structure.patch({
            name: {
                fr: '',
            },
            original_name: {
                fr: '',
            },
            content_type: 'rab',
            slug: '',
            pack_id: '',
            company_id: null,
            description: {
                fr: '',
            },
            nb_minutes: '',
            can_see_question_stats: true,
            objectives: [],
            languages: {
                fr: {
                    lang_code: 'fr',
                },
            },
        })
    }

    patch(data) {
        super.patch(data)
        this.formObjectives = this.getComputedObjectives()
        return this
    }

    setComputedObjectives(objectives) {
        const objectivesByLang = {}
        objectives.forEach((o) => {
            for (let lang in o.content) {
                if (!objectivesByLang[lang]) objectivesByLang[lang] = []
                if (!o.index) o.index = objectivesByLang[lang].length
                objectivesByLang[lang][o.index] = o.content[lang]
            }
        })
        this.objectives = objectivesByLang
    }

    /**
     * Permet de parser le resultat du formulaire en objet lisible par l'api
     * TODO: changer l'api pour ne plus avoir a faire ça (demande une refacto côté casque)
     * @returns {null}
     */
    getComputedObjectives() {
        const objectivesByLang = this?.objectives
        if (objectivesByLang instanceof Array) return objectivesByLang
        let res = null
        for (let lang in objectivesByLang) {
            if (res == null)
                res = objectivesByLang[lang]?.map((o, index) => {
                    return {
                        index,
                        content: {
                            [lang]: o,
                        },
                    }
                })
            else {
                objectivesByLang[lang].forEach((o, index) => {
                    if (res.length > index) res[index].content[lang] = o
                    else {
                        res.push({
                            index,
                            content: {
                                [lang]: o,
                            },
                        })
                    }
                })
            }
        }
        return res
    }

    async fetch(query = null, evaluated = 'data.data') {
        await this.fetchWithModifier(query, (data) => eval(evaluated))
    }
    /**
     * Fait une requête pour récupérer la formation et transforme éventuellement la réponse avant de l'assigner à la structure.
     * @param {La query pour récupérer la Formation} query
     * @param {Le callback pour transformer les données issue de la réponse de l'API} mutatorCallback
     */
    async fetchWithModifier(query = null, modifier, requestModifier) {
        this.query = query || this.query || {}
        let request = FormationsProvider.getFormation(this.id, this.query)
        if (requestModifier != null) request = requestModifier(request)
        if (modifier != null) {
            request = request.addModifier(modifier)
        }

        let data = await request.call()
        this.patch(data)
    }

    getIsPublished(lang) {
        if (this.languages[lang] === undefined) return false
        return Boolean(this.languages[lang].published)
    }

    getIsPublishedBeta(lang) {
        if (this.languages[lang] === undefined) return false
        return Boolean(this.languages[lang].published_beta)
    }

    setIsPublished(lang, value) {
        this.setLanguage(lang, { published: value })
    }

    setIsPublishedBeta(lang, value) {
        this.setLanguage(lang, { published_beta: value })
    }

    setLanguage(lang, obj) {
        if (this.languages[lang] === undefined)
            this.languages[lang] = FormationLanguageStructure.getDefault(lang)
        this.languages[lang] = { ...this.languages[lang], ...obj }
    }

    setCertificateTemplate(lang, file) {
        this.setLanguage(lang, { certificate_template: file })
    }

    getCertificateTemplate(lang) {
        return this?.languages[lang]?.certificate_template
    }

    setVideo(lang, file) {
        this.setLanguage(lang, { video: file })
    }

    getVideo(lang) {
        return this?.languages[lang]?.video
    }

    setEducationalGuide(lang, file) {
        this.setLanguage(lang, { educational_guide: file })
    }

    getEducationalGuide(lang) {
        return this?.languages[lang]?.educational_guide
    }

    setSynthesis(lang, file) {
        this.setLanguage(lang, { synthesis_pdf: file })
    }

    getSysthesis(lang) {
        return this?.languages[lang]?.synthesis_pdf
    }

    getSlugFallback() {
        return this.slug || this.name.replace(/[ -]/gi, '_').toLowerCase()
    }
    getEducationalGuideUrl() {
        let query = `?rename_file=${i18n.global.t('resources.educational_guide_rename_file')}_${this.getSlugFallback()}.pdf`
        return this.educational_guide.url + query
    }
    getSynthesisUrl() {
        let query = `?rename_file=${i18n.global.t('resources.synthesis_rename_file')}_${this.getSlugFallback()}.pdf`
        return this.synthesis_pdf.url + query
    }
}

class FormationLanguageStructure extends Structure {
    constructor(...args) {
        super(...args)
    }

    static getDefault(lang) {
        const structure = new FormationLanguageStructure()
        return structure.patch({
            lang_code: lang,
            published: false,
            published_beta: false,
            educational_guide: null,
            synthesis_pdf: null,
            video: null,
        })
    }
}
