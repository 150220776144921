<script>
    import { mapState } from 'pinia'
    import { useLanguageStore } from '../../store/lang.js'
    import moment from 'moment'
    import DatePicker from '@ui/DatePicker.vue'

    export default {
        name: 'StatistiquesViewDateFilter',
        components: { DatePicker },
        props: {
            modelValue: {
                type: Array,
                default: null,
            },
            dateType: {
                type: Number,
                default: 0,
            },
        },
        data() {
            return {
                selectedDate: [],
            }
        },
        computed: {
            ...mapState(useLanguageStore, ['currentLocale']),
            textDateRangeInput() {
                if (
                    !this.selectedDate ||
                    !this.selectedDate.length ||
                    this.valueDateType !== 3
                )
                    return this.$t('stats.filters.periods.period_custom')
                let from = moment(this.selectedDate[0]).format('L')
                let to = moment(this.selectedDate[1]).format('L')
                return `${from} - ${to}`
            },
            dateRange: {
                get() {
                    return this.modelValue
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                },
            },
            valueDateType: {
                get() {
                    return this.dateType
                },
                set(value) {
                    this.$emit('update:dateType', value)
                },
            },
            periodsInputs() {
                return [
                    {
                        title: this.$t('stats.filters.periods.period_L7D'),
                        value: 7,
                        id: 0,
                    },
                    {
                        title: this.$t('stats.filters.periods.period_L30D'),
                        value: 30,
                        id: 1,
                    },
                    {
                        title: this.$t('stats.filters.periods.period_LDD'),
                        value: -1,
                        id: 2,
                    },
                ]
            },
        },
        mounted() {
            moment.locale(this.currentLocale)
        },
        methods: {
            setDateRange(from, to) {
                this.dateRange = [
                    from instanceof Date
                        ? moment(from).format('YYYY-MM-DD')
                        : null,
                    moment(to).format('YYYY-MM-DD'),
                ]
            },

            handlePeriodInputClick(input) {
                this.selectedDate = []
                this.valueDateType = input.id
                const now = new Date()
                if (input.value === -1) {
                    this.setDateRange(null, now)
                    return
                }
                const from = new Date(
                    now.getTime() - input.value * 24 * 60 * 60 * 1000
                )
                this.setDateRange(from, now)
            },

            handleRangeSelect() {
                this.valueDateType = 3
                this.setDateRange(...this.selectedDate)
            },
        },

        watch: {
            dateType(val) {
                if (val === 3) {
                    //parse dateRange to create selectedDate
                    this.selectedDate = this.dateRange.map(
                        (date) => new Date(date)
                    )
                }
            },
        },
    }
</script>

<template>
    <ra-stack
        alignement="center"
        class="date-filter-container"
        direction="horizontal"
        margin=",,4,"
        mobile-swap
        wrap
    >
        <ra-typo uppercase>{{ $t('stats.filters.periods.label') }}</ra-typo>
        <v-btn
            v-for="input in periodsInputs"
            :data-active="input.id === valueDateType"
            color="secondary"
            size="small"
            variant="flat"
            @click="handlePeriodInputClick(input)"
        >
            {{ input.title }}
        </v-btn>
        <date-picker
            v-model:dates="selectedDate"
            :max-date="new Date()"
            range
            @validated="handleRangeSelect"
        >
            <template #activator>
                <v-btn
                    :data-active="valueDateType == 3"
                    color="secondary"
                    size="small"
                    variant="flat"
                >
                    {{ textDateRangeInput }}
                </v-btn>
            </template>
        </date-picker>
    </ra-stack>
</template>

<style lang="scss">
    .date-filter-container {
        [data-active='false'] {
            background-color: rgba(144, 146, 179, 0.2) !important;
            border-color: rgba(144, 146, 179, 0.2) !important;
            color: var(--primary) !important;
        }
        .v-btn {
            transition: 0s;
        }
    }
</style>
