import { GlobalStatStructure } from '@libs/Structures/GlobalStatStructure.js'
import { Structure } from '@libs/Structures/Structure.js'
import { reactive } from 'vue'
import moment from 'moment'

export class StatistiqueStructure extends Structure {
    constructor(
        ressourceId,
        ressourceFetcher,
        ressourceExportFetcher,
        ...args
    ) {
        super(...args)
        this.ressourceId = ressourceId
        this.ressourceFetcher = ressourceFetcher
        this.ressourceExportFetcher = ressourceExportFetcher
        let from = new Date()
        let to = new Date()
        from.setDate(to.getDate() - 7)
        this.query = {
            formers_ids: [],
            formation_ids: [],
            dateType: 0,
            dates_range: [
                moment(from).format('DD-MM-YYYY'),
                moment(to).format('DD-MM-YYYY'),
            ],
        }
        this.loading = false
        this.lastFetched = null

        return reactive(this)
    }

    get evaluation_repartition() {
        let val = this.evaluation
        return Array.isArray(val) ? (val.length ? val : null) : null
    }

    get questions_repartition() {
        return (
            this?.questions || {
                END_QUESTION: null,
                BEGIN_QUESTION: null,
            }
        )
    }

    get global() {
        if (!this._global) return Array(6).fill(null)
        return new GlobalCompaniesStatsStructure(this._global).grid
    }

    set global(val) {
        this._global = val
    }

    patch(...args) {
        super.patch(...args)
    }
    reset() {
        this.global = null
        delete this.formation_ranking
        delete this.evaluation
        delete this.questions
        delete this.sessions_done
        delete this.session_completed
        delete this.success_rate
    }

    setQuery(newQuery) {
        this.query = {
            ...this.query,
            ...newQuery,
        }
    }

    async fetch(query = null) {
        if (query) this.setQuery(query)
        this.loading = true
        this.lastFetched = new Date()
        this.reset()
        let requestQuery = {
            ...this.query,
            start_date: this.query.dates_range?.length
                ? this.query?.dates_range[0]
                : null,
            end_date: this.query.dates_range?.length
                ? this.query?.dates_range[1]
                : null,
            dates_range: null,
        }

        let request
        if (this.ressourceId) {
            request = this.ressourceFetcher(this.ressourceId, requestQuery)
        } else {
            request = this.ressourceFetcher(requestQuery)
        }
        const response = await request.call()
        this.patch(response.data)
        this.loading = false
        return this
    }

    async exportCSV() {
        let requestQuery = {
            ...this.query,
            start_date: this.query.dates_range?.length
                ? this.query?.dates_range[0]
                : null,
            end_date: this.query.dates_range?.length
                ? this.query?.dates_range[1]
                : null,
            dates_range: null,
        }
        const response = await this.ressourceExportFetcher(
            this.ressourceId,
            requestQuery
        ).call()
        return response
    }
}

export class GlobalCompaniesStatsStructure extends GlobalStatStructure {
    constructor(...args) {
        super(...args)
    }
    get grid() {
        return [
            {
                icon: 'fa-calendar',
                label: this.$t(this.globalTradKey + '.session_count'),
                value: this.toInt(this.sessions_count),
            },
            {
                icon: 'fa-graduation-cap',
                label: this.$tc(
                    this.globalTradKey + '.formation_done',
                    this.toInt(this.formation_done)
                ),
                value: this.toInt(this?.sessions_completed),
            },
            {
                icon: 'fa-satisfaction-rate',
                label: this.$t(this.globalTradKey + '.satisfaction_rate'),
                value: this.toPercent(this?.satisfaction_rate),
            },
            {
                icon: 'fa-time-spent',
                label: this.$t(this.globalTradKey + '.total_time'),
                value: this.toDuration(this?.time_passed),
            },
            {
                icon: 'fa-success-average',
                label: this.$t(this.globalTradKey + '.success_average'),
                value: this.toPercent(this?.success_rate),
            },
            {
                icon: 'fa-certificate-count',
                label: this.$tc(
                    this.globalTradKey + '.certificate_count',
                    this.toInt(this?.certificate_count)
                ),
                value: this.toInt(this?.certificate_count),
            },
        ]
    }
}
