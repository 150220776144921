<script>
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import { mapActions } from 'pinia'
    import { Skeletor } from 'vue-skeletor'
    import { StatistiqueStructure } from '@libs/Structures/StatistiqueStructure.js'
    import { LicenseCollection } from '@libs/Collections/LicenseCollection.js'
    import { CompaniesStatsExport } from '@libs/PDFExports/CompaniesStatsExport.js'
    import { LicensesProvider } from '@core/api/providers/LicensesProvider.js'
    import { useGlobalDialog } from '@store/globalDialog.js'
    import StatistiquesViewDateFilter from '@components/Stats/StatistiquesViewDateFilter.vue'
    import SessionsAutoEvaluationQuesionsRepartion from './SessionsAutoEvaluationQuesionsRepartion.vue'
    import GlobalStatsGrid from './GlobalStatsGrid.vue'
    import ChartProgressCircle from './Charts/ChartProgressCircle.vue'
    import ChartSessionDones from './Charts/ChartSessionsDones.vue'
    import SessionsFormationRankingTable from './SessionsFormationRankingTable.vue'
    import withUser from '@mixins/withUser.js'
    import moment from 'moment'
    /* eslint-disable vue/no-mutating-props */
    export default {
        name: 'StatistiquesView',
        components: {
            Skeletor,
            SessionsAutoEvaluationQuesionsRepartion,
            SessionsFormationRankingTable,
            ChartSessionDones,
            ChartProgressCircle,
            GlobalStatsGrid,
            StatistiquesViewDateFilter,
        },
        mixins: [mixins.RaDialogsMixin, withUser],
        props: {
            stats: {
                type: StatistiqueStructure,
                default: null,
            },
            company: Object,
            former: Object,
            formationScoped: {
                type: Boolean,
                default: false,
            },
            formerScoped: {
                type: Boolean,
                default: false,
            },
            hideDate: {
                type: Boolean,
                default: false,
            },
            onlyFormations: {
                type: Array,
                default: [],
            },
        },
        data() {
            return {
                loading: false,
                exporting: false,
                formationsAvailable: [],
                licenses: null,
            }
        },

        watch: {
            // Watch for changes in filters -> fetch data
            'stats.query': {
                async handler() {
                    this.saveFilters()
                    await this.stats.fetch()
                    this.setFormationAvailable()
                },
                deep: true,
            },
            currentLocale() {
                this.fetch()
            },
        },

        mounted() {
            this.init()
        },
        methods: {
            ...mapActions(useGlobalDialog, ['setGlobalDialog']),
            async init() {
                await this.fetchLicenses()
                this.readFilters(() => {})
            },

            saveFilters() {
                this.$router.replace({
                    query: this.stats.query,
                    params: this.$route.params,
                })
            },
            readFilters(cb = () => {}) {
                let query = {}
                let parser = {
                    dateType: (val) => Number(val),
                    formers_ids: (val) =>
                        Array.isArray(val) ? val.map(Number) : [Number(val)],
                    formation_ids: (val) =>
                        Array.isArray(val) ? val.map(Number) : [Number(val)],
                    dates_range: (val) => val,
                }
                for (let prop in this.$route.query) {
                    let val = this.$route.query[prop]
                    if (prop in parser) query[prop] = parser[prop](val)
                }

                if (this.formerScoped) query.formers_ids = [this.former.id]

                this.stats.setQuery(query)
                cb()
            },

            async fetchLicenses() {
                const { data } = await LicensesProvider.getLicenses(
                    this.company.id,
                    {
                        licenseTypes: [7, 8, 5, 6],
                    }
                ).call()
                this.licenses = new LicenseCollection(data)
                this.setFormationAvailable()
            },

            setFormationAvailable() {
                if (!this.licenses) return
                this.formationsAvailable = this.licenses.formations
                    .filter((formation) => {
                        return this.onlyFormations.length > 0
                            ? this.onlyFormations.includes(formation.id)
                            : this.stats?.formations_ids &&
                                this.stats?.formations_ids.length > 0
                              ? this.stats?.formations_ids.includes(
                                    formation.id
                                )
                              : true
                    })
                    .sort((a, b) => a.pack.order - b.pack.order)
            },

            async exportPDF() {
                this.exporting = true
                let exporter = new CompaniesStatsExport(
                    this.former,
                    this.company,
                    this.$refs['pdf-export']
                )
                const newDateRange = this.stats.query.dates_range.map((date) =>
                    moment(date, 'YYYY-MM-DD').format('DD/MM/YY')
                )
                await exporter.export()
                exporter.title()
                exporter.period(this.stats.query.dateType, newDateRange)
                const date = new Date()
                const day = date.getDate().toString().padStart(2, '0')
                const month = (date.getMonth() + 1).toString().padStart(2, '0')
                const year = date.getFullYear().toString()
                const name = `stats_export_reality_academy_${day}_${month}_${year}`
                exporter.download(name)
                this.exporting = false
            },

            async exportCSV() {
                this.exporting = true
                await this.stats.exportCSV()
                this.exporting = false
                this.setGlobalDialog({
                    title: this.$t('stats.export.csv_dialog.title'),
                    message: this.$t('stats.export.csv_dialog.message', {
                        email: this.user.email,
                    }),
                    negativeButton: null,
                    positiveButton: {
                        title: this.$t('generics.continue'),
                    },
                    visible: true,
                })
            },
        },
    }
</script>

<template>
    <div ref="pdf-export">
        <ra-stack gap="4">
            <ra-stack
                alignement="center"
                class="row-filter-export"
                direction="horizontal"
                mobile-swap
                wrap
            >
                <ra-filters v-if="!formationScoped || !formerScoped">
                    <ra-tree-formations-select
                        v-if="!formationScoped"
                        v-model="stats.query.formation_ids"
                        :items="formationsAvailable"
                        :label="$tc('formations._name', 2)"
                        density="compact"
                        multiple
                    />
                    <ra-user-selector
                        v-if="!formerScoped"
                        v-model="stats.query.formers_ids"
                        :company_id="company.id"
                        :label="$tc('formers._name', 2)"
                        :multiple="true"
                        :placeholder="$tc('formers._name', 2)"
                        density="compact"
                    />
                </ra-filters>
                <ra-box v-else></ra-box>
                <v-menu>
                    <template #activator="{ props }">
                        <v-btn
                            :loading="exporting"
                            color="primary-gradient"
                            prepend-icon="mdi-download"
                            v-bind="props"
                            variant="flat"
                        >
                            {{ $t('stats.export.cta') }}
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="exportPDF">
                            <ra-stack direction="horizontal">
                                <ra-icon>fa-file-pdf</ra-icon>
                                {{ $t('stats.export.pdf') }}
                            </ra-stack>
                        </v-list-item>
                        <v-list-item
                            v-if="stats.ressourceExportFetcher"
                            @click="exportCSV"
                        >
                            <ra-stack direction="horizontal">
                                <ra-icon>fa-file-csv</ra-icon>
                                {{ $t('stats.export.csv') }}
                            </ra-stack>
                        </v-list-item>
                        <ra-typo
                            padding="1,4"
                            variant="x-small"
                            width="270px"
                        >
                            {{ $t('stats.export.help') }}
                        </ra-typo>
                    </v-list>
                </v-menu>
            </ra-stack>
            <statistiques-view-date-filter
                v-if="!hideDate"
                v-model="stats.query.dates_range"
                v-model:dateType="stats.query.dateType"
            ></statistiques-view-date-filter>

            <slot></slot>
            <template v-if="!$slots.default">
                <ra-stack
                    data-pdf-page="1"
                    data-pdf-row="1"
                    direction="horizontal"
                    distribution="fill"
                    gap="4"
                    mobile-swap
                >
                    <ra-card
                        flex="1 1 100%"
                        rounded
                    >
                        <template #title>
                            {{ $t('stats.globals.title') }}
                        </template>
                        <global-stats-grid :stats="stats.global" />
                    </ra-card>
                    <ra-card flex="1 0 300px">
                        <template #title>
                            {{ $t('stats.completion_rate.title') }}
                        </template>
                        <chart-progress-circle
                            v-if="stats?._global"
                            :completion="stats?._global?.completion_rate"
                            :label="$t('stats.completion_rate.label')"
                            size="200"
                        />
                        <ra-box
                            v-else
                            centered-content
                            padding="2,"
                        >
                            <Skeletor
                                as="div"
                                height="230"
                                width="100%"
                            />
                        </ra-box>
                    </ra-card>
                </ra-stack>
                <ra-card
                    v-if="stats.formation_ranking"
                    data-pdf-page="1"
                    data-pdf-row="2"
                >
                    <template #title>{{
                        $t('stats.formation_ranking.title')
                    }}</template>
                    <sessions-formation-ranking-table
                        :formation_ranking="stats.formation_ranking"
                    />
                </ra-card>
                <ra-card
                    v-if="stats.success_rate && stats.sessions_done"
                    data-pdf-page="1"
                    data-pdf-row="3"
                >
                    <template #title>
                        {{ $t('stats.formation_done.title') }}
                    </template>
                    <ra-box padding="4,">
                        <chart-session-dones
                            :sessions_done="stats.sessions_done"
                            :success_rate="stats.success_rate"
                        />
                    </ra-box>
                </ra-card>
                <ra-card
                    v-if="stats.evaluation_repartition"
                    class="rounded-lg pa-6"
                    data-pdf-page="1"
                    data-pdf-row="4"
                >
                    <template #title>
                        {{ $t('stats.quiz_analysis.title') }}
                    </template>
                    <chart-evaluation-repartition
                        :evaluation="stats.evaluation_repartition"
                    />
                </ra-card>
                <div
                    v-if="
                        stats.questions_repartition.BEGIN_QUESTION ||
                        stats.questions_repartition.END_QUESTION
                    "
                    data-pdf-page="2"
                    data-pdf-row="1"
                >
                    <div
                        v-if="stats.questions_repartition.BEGIN_QUESTION"
                        class="col-12 page-break-before"
                        style="page-break-before: always"
                    >
                        <ra-card>
                            <template #title>
                                {{
                                    $t(
                                        'stats.auto_evaluation_questions.pre_eval_title'
                                    )
                                }}
                            </template>
                            <sessions-auto-evaluation-quesions-repartion
                                :questions="
                                    stats.questions_repartition.BEGIN_QUESTION
                                "
                            />
                        </ra-card>
                    </div>
                    <div
                        v-if="stats.questions_repartition.END_QUESTION"
                        class="col-12"
                    >
                        <ra-card>
                            <template #title>
                                {{
                                    $t(
                                        'stats.auto_evaluation_questions.post_eval_title'
                                    )
                                }}
                            </template>
                            <sessions-auto-evaluation-quesions-repartion
                                :questions="
                                    stats.questions_repartition.END_QUESTION
                                "
                            />
                        </ra-card>
                    </div>
                </div>
            </template>
        </ra-stack>
    </div>
</template>

<style lang="scss" scoped>
    .stats .v-sheet.v-card {
        box-shadow: none !important;
    }
    @media (max-width: 600px) {
        .dates-filters-btn {
            width: 100%;
            button {
                width: 100%;
            }
        }
    }
</style>

<style lang="scss">
    .row-filter-export {
        justify-content: space-between;
        .ra-filters {
            max-width: 500px;
        }
    }
</style>
